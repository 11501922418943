@import "~@/styles/variables.scss";




























































































.vab-nav-icon {
  margin-right: 4px;
}

::v-deep {
  .el-tag {
    float: right;
    height: 16px;
    padding-right: 4px;
    padding-left: 4px;
    margin-top: calc((#{$base-menu-item-height} - 16px) / 2);
    line-height: 16px;
    border: 0;
  }
}
