@import "~@/styles/variables.scss";















































































.app-main-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    .vab-keel {
        margin: $base-padding;
    }

    .app-main-height {
        min-height: $base-app-main-height;
    }

    .footer-copyright {
        min-height: 55px;
        line-height: 55px;
        color: rgba(0, 0, 0, 0.45);
        text-align: center;
        border-top: 1px dashed $base-border-color;
    }
}
